import moment from 'moment';

export const URL = {
  reserve: 'https://www.tablecheck.com/shops/jinbocho-gokita/reserve',
  ec: 'https://foretriziere.base.shop/',
  instagram: 'https://www.instagram.com/gokita_jinbocho',
  facebook:
    'https://www.facebook.com/pages/category/Yakitori-Restaurant/%E7%A5%9E%E4%BF%9D%E7%94%BA-%E4%BA%94%E6%9C%A8%E7%94%B0-102786265377626/',
} as const;

export const formatDate = (date?: string) => moment(date).format('YYYY.MM.DD.');
