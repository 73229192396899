import * as React from 'react';
import { styled } from 'linaria/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Div = styled.div`
  .eng_btn {
    &:hover {
      opacity: 0.8;
    }
  }
`;

const EngButton: React.FC<{ min?: boolean }> = ({ min }) => {
  const device = useBreakpoint();
  const isSmallViwer = device.m;

  return (
    <Div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <a
        href="https://www.pocket-concierge.jp/en/restaurants/245438?date=2024-09-20&partySize=2&serviceType=DINNER"
        target="_blank"
        rel="noreferrer"
        className="eng_btn"
        style={{
          color: 'var(--white)',
          fontFamily: 'var(--font-en-serif)',
          padding: isSmallViwer ? '0.25rem 2rem' : '1rem 2rem',
          margin: isSmallViwer ? '0.25rem 2rem' : '1rem 2rem',
          background: 'var(--theme-blue)',
          fontSize: min ? '90%' : 'auto',
        }}
      >
        English
      </a>
    </Div>
  );
};
export default EngButton;
