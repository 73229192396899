import * as React from 'react';
import { styled } from 'linaria/react';
import { URL } from '../Utils';

const Link = styled.a`
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;
const ReservationLinker = () => (
  <Link href={URL.reserve} target="_blank" rel="noopener noreferrer">
    <div
      id="reservation-linker"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '10em',
        height: '10em',
        position: 'fixed',
        right: 0,
        bottom: '5%',
        background: 'var(--theme-gold)',
        opacity: 0.8,
        color: 'var(--white)',
        textAlign: 'center',
      }}
    >
      <h2 style={{ position: 'relative', width: '100%' }}>Reservation</h2>
      <h3>ご予約はこちら</h3>
    </div>
  </Link>
);

export default ReservationLinker;
