import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { styled } from 'linaria/react';
import Seo from '../components/seo';
import Navi from '../components/Navi';
import ReservationLinker from '../components/ReservationLinker';

export const inViewConfig = {
  rootMargin: '0px',
  triggerOnce: true,
};
export const fadeIn = 'animate__animated animate__fadeIn';
export const fadeInUp = 'animate__animated animate__fadeInUp';
export const delay1 = ' animate__delay-1s';
export const delay2 = ' animate__delay-2s';
export const delay3 = ' animate__delay-3s';
export const delay4 = ' animate__delay-4s';

export const Section: React.FC<{ id?: string; className?: string; style?: React.CSSProperties | undefined }> = ({
  id,
  children,
  className,
  style,
}) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  return (
    <section
      id={id}
      style={{
        // margin: '8em auto',
        paddingTop: '4em',
        marginBottom: '4em',
        ...style,
        ...(!isSmallViewer && { paddingLeft: '8%' }),
      }}
      className={className}
    >
      {children}
    </section>
  );
};

const Main = styled.main`
  margin-bottom: 1.5em;
  width: 100%;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--theme-dark);
  color: var(--white);
  font-family: var(--font-jp-sans);
  font-weight: 100;
  text-align: center;
  line-height: 1.5;
`;

const CommonLayout: React.FC<{ title?: string; article?: boolean }> = ({ title, article, children }) => {
  const data = useStaticQuery<GatsbyTypes.SiteTitleQueryQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const siteTitle = title || data.site?.siteMetadata?.title || '神保町 五木田';

  const device = useBreakpoint();

  return (
    <>
      <Seo title={siteTitle} article={article} />
      <div style={{ display: 'flex' }}>
        <Main style={{ paddingLeft: device.m ? '0px' : '150px', marginTop: device.m ? '2em' : 0 }}>
          {children}
          <ReservationLinker />
        </Main>
        <Navi title={siteTitle} />
        <Footer>© {new Date().getFullYear()} forêt rizière</Footer>
      </div>
    </>
  );
};

export const SubDirectorySection: React.FC<{ style?: React.CSSProperties | undefined }> = ({ style, children }) => (
  <Section
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...style,
    }}
  >
    {children}
  </Section>
);
export default CommonLayout;
