import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { styled } from 'linaria/react';
import { URL } from '../Utils';
import LogoSVG from '../assets/svg/logo/navi.imp.svg';
import LogoHorizontalSVG from '../assets/svg/logo/horizontal.imp.svg';
import Insta from '../assets/svg/sns/insta.imp.svg';
import FB from '../assets/svg/sns/fb.imp.svg';
import Scroll from '../assets/svg/arrow/scroll.svg';
import OpenerSVG from '../assets/svg/navi_opener.imp.svg';
import CloserSVG from '../assets/svg/close.imp.svg';
import NaviSVG from '../assets/svg/arrow/navi.svg';
import NaviSVGActive from '../assets/svg/arrow/navi_active.svg';
import EngButton from './EngButton';

const Nav = styled.nav`
  position: fixed;
  height: 100%;
  background: var(--theme-gray);
  color: var(--theme-blue);
  font-family: var(--font-en-serif);
  font-size: 13px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #navigation li,
  #sns li {
    height: 42px;
  }
  #navigation li {
    box-sizing: content-box;
    border-top: 1px solid;
  }
  #navigation a {
    width: 100%;
    height: 100%;
    line-height: 42px;
    text-align: center;
    vertical-align: middle;
  }
  #sns {
    border-top: 1px solid;
    display: flex;
    padding: 0 14px;
    justify-content: center;
  }
  #sns li {
    margin: auto 1.5em;
  }
  .active {
    background: var(--theme-blue) center right 5px/ 10px url(${NaviSVGActive}) no-repeat;
    a {
      color: var(--theme-gray);
    }
  }
  .linkStyle {
    background: center right 5px/ 10px url(${NaviSVG}) no-repeat;
    a {
      color: var(--theme-blue);
    }
    :hover {
      background: var(--theme-blue) center right 5px/ 10px url(${NaviSVGActive}) no-repeat;
      a {
        color: var(--theme-gray);
      }
    }
  }
`;

const Logo = ({ title, onClose }: { title: string; onClose: () => void }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  return (
    <Link to="/" onClick={isSmallViewer ? onClose : undefined}>
      <LogoSVG title={title} style={{ width: '100%', padding: isSmallViewer ? '0 40%' : '30px 25px' }} />
    </Link>
  );
};

const NaviItem: React.FC<{ uri: string; onClose: () => void }> = ({ uri, onClose, children }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;
  return (
    <li className={uri.includes('/#') ? 'linkStyle' : 'sns-item'}>
      <Link
        to={uri}
        target={uri.includes('/#') ? '_self' : '_blank'}
        onClick={() => {
          if (isSmallViewer) onClose();
        }}
      >
        {children}
      </Link>
    </li>
  );
};

const SNS = ({ onClose }: { onClose: () => void }) => (
  <ul id="sns">
    <NaviItem uri={URL.instagram} onClose={onClose}>
      <div style={{ width: '20px', height: '20px' }}>
        <Insta title="Instagram" />
      </div>
    </NaviItem>
    <NaviItem uri={URL.facebook} onClose={onClose}>
      <div style={{ width: '20px', height: '20px' }}>
        <FB title="Facebook" />
      </div>
    </NaviItem>
  </ul>
);

const Opener = ({ onClick }: { onClick: () => void }) => (
  <div
    style={{
      position: 'fixed',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      background: 'var(--theme-dark)',
    }}
  >
    <div style={{ position: 'absolute', width: '150px', top: '0.1em', left: '0.5em' }}>
      <LogoHorizontalSVG title="神保町 五木田" fill="var(--white)" />
    </div>
    <EngButton min />
    <div
      style={{
        width: '2em',
        height: '2em',
        cursor: 'pointer',
        padding: '0.5em 0.25em 0 0.25em',
        marginRight: '0.5em',
      }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <OpenerSVG title="navi" />
    </div>
  </div>
);

const Closer = ({ onClick }: { onClick: () => void }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <div
      style={{
        position: 'relative',
        width: '2em',
        height: '2em',
        right: '0',
        margin: '0.5em',
        cursor: 'pointer',
      }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <CloserSVG title="close" />
    </div>
  </div>
);

const Navi = ({ title, onClose }: { title: string; onClose: () => void }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  return (
    <Nav
      style={{
        width: isSmallViewer ? '100%' : '150px',
        ...(!isSmallViewer && { background: `top 49.8em right 16.5% /5% url(${Scroll}) no-repeat` }),
      }}
      className={isSmallViewer ? 'show' : 'show scroller'}
    >
      <div style={{ background: 'var(--theme-gray)' }}>
        {isSmallViewer ? <Closer onClick={onClose} /> : <Logo title={title} onClose={onClose} />}
        <ul id="navigation">
          <NaviItem uri="/#concept" onClose={onClose}>
            Concept
          </NaviItem>
          <NaviItem uri="/#team" onClose={onClose}>
            Our Team
          </NaviItem>
          <NaviItem uri="/#menu" onClose={onClose}>
            Menu
          </NaviItem>
          <NaviItem uri="/#space" onClose={onClose}>
            Space
          </NaviItem>
          <NaviItem uri="/#reservation" onClose={onClose}>
            Reservation
          </NaviItem>
          <NaviItem uri="/#store" onClose={onClose}>
            Online Store
          </NaviItem>
          <NaviItem uri="/#info" onClose={onClose}>
            Shop Info
          </NaviItem>
        </ul>
        <SNS onClose={onClose} />
        <EngButton min />
        {isSmallViewer ? (
          <Logo title={title} onClose={onClose} />
        ) : (
          <div
            style={{
              writingMode: 'vertical-lr',
              position: 'absolute',
              right: '24px',
              padding: '12px 0',
              fontSize: 10,
              background: 'var(--theme-gray)',
            }}
          >
            scroll
          </div>
        )}
      </div>
    </Nav>
  );
};

const View = ({ title }: { title: string }) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;
  const [visible, setVisible] = useState(!isSmallViewer);
  useEffect(() => {
    setVisible(!device.m);
  }, [device.m]);

  const onClick = () => {
    setVisible(!visible);
  };

  return visible ? <Navi title={title} onClose={onClick} /> : <Opener onClick={onClick} />;
};

export default View;
