/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

type SEO = {
  title: string;
  description?: string;
  lang?: string;
  meta?: HTMLMetaElement[];
  article?: boolean;
};

const Seo = ({
  title = '神保町 五木田',
  description = '東京神保町に店を構えるフランス料理x焼鳥という今までにない新しい業態のレストランです。',
  lang = 'ja',
  meta = [],
  article = false,
}: SEO) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title || title || '神保町 五木田';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: article ? 'article' : 'website',
        },
        {
          property: 'og:url',
          content: site.siteMetadata?.siteUrl ?? 'https://jinbocho-gokita.com',
        },
        {
          property: 'og:image',
          content:
            'https://images.microcms-assets.io/assets/1d14cb2b211f49f79cc8ab4c39aef4e4/d9d8222f3ba14e4dbde8dae051034619/og.png',
        },
        {
          name: `thumbnail`,
          content:
            'https://images.microcms-assets.io/assets/1d14cb2b211f49f79cc8ab4c39aef4e4/d9d8222f3ba14e4dbde8dae051034619/og.png',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || 'forêt rizière',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default Seo;
